/**
 * @ComponentFor VideoBlock
 */
import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import VideoBlockType from './VideoBlock.type';
import VideoSource from './VideoSource.type';
import AspectRatio, { Ratio } from './../../AspectRatio';
import padStyled from 'Shared/Blocks/PaddedBlock';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';

type PropType = VideoBlockType & StyledProps;

export const getVideoUrl = (videoId: string, videoSource: VideoSource) => {
  if (videoSource === 'youTube') {
    return `//www.youtube.com/embed/${videoId}`;
  } else if (videoSource === 'vimeo') {
    return `//player.vimeo.com/video/${videoId}`;
  }
};

const VideoIframe = styled.iframe({
  position: 'absolute',
  top: '0',
  left: '0',
  height: '100%',
  width: '100%',
});

const VideoBlock = padStyled((props: PropType) => (
  <styled.Div css={props.compose()} className={BOX_ITEM_CLASSNAME}>
    {epiPropertyValue(props.header) && <EpiProperty for={props.header} component="h2" />}
    <AspectRatio ratio={Ratio.SixteenToNine} css={{ position: 'relative' }}>
      <VideoIframe
        loading="lazy"
        frameBorder="0"
        allowFullScreen={epiPropertyValue(props.allowFullscreen)}
        src={getVideoUrl(epiPropertyValue(props.videoId), epiPropertyValue(props.videoSource))}
      />
    </AspectRatio>
    {epiPropertyValue(props.description) && <EpiProperty for={props.description} />}
  </styled.Div>
));

export default VideoBlock;
